var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"XF1lTuj0Xq6vvhxNYBcOK"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    autoSessionTracking: true,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    ignoreErrors: ["cancelRouteChange"],
    release: process.env.NEXT_PUBLIC_CURRENT_VERSION,
  });
}
